import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import getConfig from 'next/config'

import { borderRadii, colors, constants } from '../tokens'
import Container from './container'

const { publicRuntimeConfig } = getConfig()
const version = publicRuntimeConfig?.version

export default function GlobalFooter({ show = true }) {
  return (
    <Wrapper style={{ display: show ? 'block' : 'none' }}>
      <Container>
        <Stack direction={'column'} spacing={2} sx={{ padding: '0 12px' }}>
          <div>
            Copyright &copy; {new Date().getFullYear()} Madison Square Garden. All Rights Reserved
          </div>
          <span>RequesTix | App Version : {version} </span>
        </Stack>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: ${colors.globalDarkBlue};
  color: white;
  margin-top: ${constants.globalBufferMedium};
  width: 100%;
  color: #cde;
  font-size: 14px;
  padding: ${constants.globalBufferMedium} ${constants.globalBufferSmall};

  a,
  div a {
    color: ${colors.globalLightBlue};
  }
`

const SpecialMessage = styled.div`
  // font-weight: bold;
  font-style: italic;
  padding: ${constants.globalBufferSmall};
  border-radius: ${borderRadii.sm};
  border: 1px solid ${colors.globalMediumBlue};
`
